
<template>
  <div class="Echarts">
    <div :id="'main'+id" :style="{width,height}"></div>
  </div>
</template>
 
<script>
export default {
  name: 'Echarts',
  props:{
      id:{
          type:Number
      },
      width:{
        type:String,
        default:"600px"
      },
      height:{
        type:String,
        default:"400px"
      }
  },
  methods: {
    myEcharts(option){
        console.log(this.$echarts)
      var myChart = this.$echarts.init(document.getElementById('main'+this.id));
      //配置图表
      if(!option){
          option = {
            title: {
            text: 'echarts入门示例',
            },
            tooltip: {},
            legend: {
            data: ['销量']
            },
            xAxis: {
            data: ['衬衫','羊毛衫','雪纺衫','裤子','高跟鞋','袜子']
            },
            yAxis: {},
            series: [{
            name: '销量',
            type: 'bar',
            data: [5,20,36,10,10,20]
            }]
        };
      }
     
      myChart.setOption(option);
    }
  },
  mounted(){
    //this.myEcharts();
  }
}
</script>
 
<style>
 
</style>