<template>
    <div class="reveal">
        <div class="slides" v-loading="loading">
            <section :data-background-image="data.cover_bgUrl">
                <div class="flex-content" :style="style">
                <div class="title-content" style="padding-top:10%"></div>
                <div class="main-content">
                    <div class="flex-content" >
                    <h1 class="PPT-title">{{data.title}}</h1>
                    <h4 class="PPT-subtitle">{{data.company}}</h4>
                    <h5 class="PPT-date">{{data.date}}</h5>
                    <div class="bottom-selector">
                        数据时间段:
                        <el-date-picker
                            v-model="timerange"
                            prefix-icon=""
                            @change="changeDatePicker"
                            type="daterange"
                            format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                            </el-date-picker>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            <!-- 虫害情况 -->
            <section :data-background-image="bg2" data-auto-animate>
                <div class="flex-content" :style="style">
                    <div class="title-content">
                        <ppttitle :title="data.section[0].title"/>
                    </div>
                    <div class="main-content">
                        <div class="flex">
                            <ECHART ref="echart1" :id="1" :width="windowWidth/3+'px'"/>
                            <ECHART ref="echart2" :id="2" :width="windowWidth*2/3+'px'"/>
                        </div>
                        <el-table
                        border
                        :data="data.section[0].tableData"
                        stripe
                        :header-row-style="{color:'#333'}"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                        style="width: 100%;font-size:24px;text-align:center">
                            <el-table-column
                                prop="total_amount"
                                label="服务门店数量"
                                width="180">
                            </el-table-column>
                            <el-table-column
                                prop="R_account"
                                label="R级门店数量"
                                width="180">
                            </el-table-column>
                            <el-table-column
                                prop="R_mouse_account"
                                label="鼠患R级">
                            </el-table-column>
                            <el-table-column
                                prop="R_roach_account"
                                label="蟑患R级">
                            </el-table-column>
                            <el-table-column
                                prop="R_fly_account"
                                label="飞虫R级及其它">
                                <template slot-scope="scope">
                                    {{scope.row.R_fly_account+scope.row.R_other_account}}
                                </template>
                            </el-table-column>
                        </el-table>
                        <!-- 按照om分类 -->
                        <div class="margin-top"></div>
                        <el-table
                        border
                        stripe
                        :header-row-style="{color:'#333'}"
                        :data="data.section[0].tableData"
                        :header-cell-style="{'text-align':'center'}"
                        :cell-style="{'text-align':'center'}"
                        style="width: 100%;font-size:20px;text-align:center">
                            <el-table-column
                                prop="R_fly_account"
                                v-for="(item,index) in data.section[0].tableDataByOM"
                                :key="index"
                                :label="item.om">
                                <template >
                                    {{item.totalRisk.length}}家R级
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                
            </section>
            <!-- 第三方情况 -->
            <section :data-background-image="bg2" data-auto-animate v-for="(item,index) in data.extra_section.third_party_inspection" :key="index">
                <div class="flex-content" :style="style">
                    <div class="title-content">
                            <ppttitle :title="item.title" textAlign="center" icon="el-icon-warning-outline"/>
                    </div>
                    <div class="main-content flex-l-r">
                        <!-- 坐标为第三方检查内容列表 -->
                        <div class="left">
                            <div class="third_party_title">{{item.third_party_title}}</div>
                            <div class="timeline">
                                     <div class="t-l-box">
                                 <el-timeline :reverse="false">
                                         <el-timeline-item
                                            class="fade-up"
                                            size="large"
                                            v-for="(activity, index) in item.summary"
                                            :key="index"
                                            :timestamp="activity.time">
                                            {{activity.content}}
                                            <div class="img-box" v-if="activity.images">
                                                <el-image
                                                v-for="it in activity.images"
                                                :key="it"
                                                    style="width: 140px; height: 140px"
                                                    :src="it"
                                                    :preview-src-list="activity.images"
                                                    fit="fit"></el-image>
                                            </div>
                                            </el-timeline-item>
                                        </el-timeline>
                                     </div>
                                <div class="r-l"></div>
                            </div>
                        </div>
                        <!-- 右边为pco检查列表 -->
                        <div class="right">
                            <div class="third_party_title">{{item.pco_party_title}}</div>
                             <div class="timeline">
                                     <div class="r-l"></div>
                                    <div class="t-l-box">
                                        <el-timeline :reverse="false">
                                                <el-timeline-item
                                            class="fade-up"
                                            size="large"
                                            v-for="(activity, index) in item.pco_summary"
                                            :key="index"
                                            :timestamp="activity.time">
                                            {{activity.content}}
                                            <div class="img-box" v-if="activity.images">
                                                <el-image
                                                v-for="it in activity.images"
                                                :key="it"
                                                    style="width: 140px; height: 140px"
                                                    :src="it"
                                                    :preview-src-list="activity.images"
                                                    fit="fit"></el-image>
                                            </div>
                                            </el-timeline-item>
                                        </el-timeline>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 特殊情况 -->
            <section v-if="data.extra_section&&data.extra_section.extra_video_info">
                <div class="flex-content" :style="style">
                    <div class="title-content">
                        <ppttitle title="鼠患入侵情况"/>
                    </div>
                    <div class="main-content">
                        <video class="video" data-autoplay :src="data.extra_section.extra_video_info.url"></video>
                        <div class="v-list-item" v-for="(item,index) in data.extra_section.extra_video_info.list" :key="index">
                           <i :class="item.icon" /> {{item.title}}
                        </div>
                    </div>
                </div>
            </section>
            <!-- 虫害分类 -->
            <!-- 鼠患 -->
            <section :data-background-image="bg2" v-if="data.section[1]&&data.section[1].TOTAL_MOUSE_LIST">
                        <section data-auto-animate v-for="(item,index) in data.section[1].TOTAL_MOUSE_LIST" :key="index" >
                            <div class="flex-content" :style="style">
                                <div class="title-content">
                                    <ppttitle :title="'鼠患名单-'+(index+1)"/>
                                </div>
                                <div class="main-content">
                                    <el-table
                                    :data="item"
                                    border
                                    stripe
                                    :row-class-name="tableRowClassName"
                                    :header-row-style="{color:'#333','background-color':'#333!important'}"
                                    :header-cell-style="{'text-align':'center'}"
                                    :cell-style="{'text-align':'center'}"
                                    style="width: 100%;font-size:20px;text-align:center">
                                    <el-table-column
                                    label="OM"
                                    width="180">
                                    <template slot-scope="scope">
                                        {{scope.row.data[0].om}}
                                    </template>
                                    </el-table-column>
                                    <el-table-column
                                    prop="num"
                                    label="编号"
                                    width="100">
                                    </el-table-column>
                                    <el-table-column
                                    label="名称"
                                    width="180">
                                    <template slot-scope="scope">
                                        {{scope.row.data[0].client}}
                                    </template>
                                    </el-table-column>
                                    <el-table-column
                                    label="R级月份">
                                    <template slot-scope="scope">
                                        {{scope.row.Rdate||''}}
                                    </template>
                                    </el-table-column>
                                    <el-table-column
                                    label="虫害情况">
                                    <template slot-scope="scope">
                                        <div class="text-item" v-for="(it,i) in scope.row.data" :key="i">
                                            <span class="it" @click="viewAction(scope.row.data,riskIndex)" v-for="(risk,riskIndex) in it.mouseRiskList" :key="riskIndex">
                                               <!-- <i class="el-icon-time"></i> -->
                                               {{risk.time|getFormart}}
                                                {{risk.firstPlace}}>{{risk.secondPlace}} {{risk.riskDesc}}{{risk.nums}} ；
                                            </span>
                                        </div>
                                    </template>
                                    </el-table-column>
                                    <el-table-column
                                    width="180"
                                    label="图片附件">
                                    <template slot-scope="scope">
                                        <div class="text-item" v-for="(it,i) in scope.row.data" :key="i">
                                            <!-- {{it.mouseRiskList}} -->
                                            <div v-for="(risk,riskIndex) in it.mouseRiskList" :key="riskIndex">
                                              <el-image
                                                style="width: 60px; height: 60px"
                                                :src="itm"
                                                :key="indexm"
                                                :preview-src-list="risk.images||[]"
                                                v-for="(itm,indexm) in risk.images||[]"
                                                fit="fit"></el-image>
                                            </div>
                                        </div>
                                    </template>
                                    </el-table-column>
                                    <el-table-column
                                    label="改善建议">
                                    <template slot-scope="scope">
                                        {{scope.row.suggestion||''}}
                                    </template>
                                    </el-table-column>
                                    <el-table-column
                                    width="100"
                                    label="是否解决">
                                    <template slot-scope="scope">
                                        {{scope.row.status?'是':'否'}}
                                    </template>
                                    </el-table-column>
                                </el-table>
                                </div>
                            </div>
                        </section>
            </section>
            <!-- 蟑患 -->
            <section v-if="data.section[1]&&data.section[1].TOTAL_ROACH_LIST" :data-background-image="bg2">
                        <section data-auto-animate v-for="(item,index) in data.section[1].TOTAL_ROACH_LIST" :key="index" :data-background-image="bg2" >
                            <div class="flex-content" :style="style">
                                <div class="title-content">
                                    <ppttitle :title="'蟑患餐厅-'+(index+1)"/>
                                </div>
                                <div class="main-content">
                                    <el-table
                                    :data="item"
                                    border
                                    stripe
                                    :row-class-name="tableRowClassName"
                                    :header-row-style="{color:'#333'}"
                                    :header-cell-style="{'text-align':'center'}"
                                    :cell-style="{'text-align':'center'}"
                                    style="width: 100%;font-size:20px;text-align:center">
                                    <el-table-column
                                    label="OM"
                                    width="180">
                                    <template slot-scope="scope">
                                        {{scope.row.data[0].om}}
                                    </template>
                                    </el-table-column>
                                    <el-table-column
                                    prop="num"
                                    label="编号"
                                    width="100">
                                    </el-table-column>
                                    <el-table-column
                                    label="名称"
                                    width="180">
                                    <template slot-scope="scope">
                                        {{scope.row.data[0].client}}
                                    </template>
                                    </el-table-column>
                                    <el-table-column
                                    label="R级月份">
                                    <template slot-scope="scope">
                                        {{scope.row.Rdate||''}}
                                    </template>
                                    </el-table-column>
                                    <el-table-column
                                    label="虫害情况">
                                    <template slot-scope="scope">
                                        <div class="text-item" v-for="(it,i) in scope.row.data" :key="i">
                                            <span class="it" @click="viewAction(scope.row.data,riskIndex)" v-for="(risk,riskIndex) in it.roachRiskList" :key="riskIndex">
                                               <!-- <i class="el-icon-time"></i> -->
                                               {{risk.time|getFormart}}
                                                {{risk.firstPlace}}>{{risk.secondPlace}} {{risk.riskDesc}}{{risk.nums}};
                                            </span>
                                        </div>
                                    </template>
                                    </el-table-column>
                                    <el-table-column
                                    label="改善建议">
                                    <template slot-scope="scope">
                                        {{scope.row.suggestion||''}}
                                    </template>
                                    </el-table-column>
                                    <el-table-column
                                    width="100"
                                    label="是否解决">
                                    <template slot-scope="scope">
                                        {{scope.row.status?'是':'否'}}
                                    </template>
                                    </el-table-column>
                                </el-table>
                                </div>
                            </div>
                        </section>
            </section>
            <!-- 飞虫其他 -->
            <section v-if="data.section[1]&&data.section[1].TOTAL_FLY_LIST" :data-background-image="bg2">
                        <section data-auto-animate v-for="(item,index) in data.section[1].TOTAL_FLY_LIST" :key="index" :data-background-image="bg2" >
                            <div class="flex-content" :style="style">
                                <div class="title-content">
                                    <ppttitle :title="'飞虫|其它R级-'+(index+1)"/>
                                </div>
                                <div class="main-content">
                                    <el-table
                                    :data="item"
                                    border
                                    stripe
                                    :row-class-name="tableRowClassName"
                                    :header-row-style="{color:'#333'}"
                                    :header-cell-style="{'text-align':'center'}"
                                    :cell-style="{'text-align':'center'}"
                                    style="width: 100%;font-size:20px;text-align:center">
                                    <el-table-column
                                    label="OM"
                                    width="180">
                                    <template slot-scope="scope">
                                        {{scope.row.data[0].om}}
                                    </template>
                                    </el-table-column>
                                    <el-table-column
                                    prop="num"
                                    label="编号"
                                    width="100">
                                    </el-table-column>
                                    <el-table-column
                                    label="名称"
                                    width="180">
                                    <template slot-scope="scope">
                                        {{scope.row.data[0].client}}
                                    </template>
                                    </el-table-column>
                                    <el-table-column
                                    label="R级月份">
                                    <template slot-scope="scope">
                                        {{scope.row.Rdate||''}}
                                    </template>
                                    </el-table-column>
                                    <el-table-column
                                    label="虫害情况">
                                    <template slot-scope="scope">
                                        <div class="text-item" v-for="(it,i) in scope.row.data" :key="i">
                                            <span class="it" @click="viewAction(scope.row.data,riskIndex)" v-for="(risk,riskIndex) in it.flyRiskList" :key="riskIndex">
                                               <!-- <i class="el-icon-time"></i> -->
                                               {{risk.time|getFormart}}
                                                {{risk.firstPlace}}>{{risk.secondPlace}} {{risk.riskDesc}}{{risk.nums}};
                                            </span>
                                        </div>
                                    </template>
                                    </el-table-column>
                                    <el-table-column
                                    label="改善建议">
                                    <template slot-scope="scope">
                                        {{scope.row.suggestion||''}}
                                    </template>
                                    </el-table-column>
                                    <el-table-column
                                    width="100"
                                    label="是否解决">
                                    <template slot-scope="scope">
                                        {{scope.row.status?'是':'否'}}
                                    </template>
                                    </el-table-column>
                                </el-table>
                                </div>
                            </div>
                        </section>
            </section>
            <!-- daochu -->
            <section :data-background-image="bg2" data-auto-animate>
                <div class="flex-content" :style="style">
                    <div class="title-content" style="flex:1"></div>
                    <div style="flex:2"><h1 @click="save">THANKS!</h1></div>
                </div>
            </section>
        </div>
        <!-- 全面检查 -->
        <el-dialog append-to-body
        width="60%"
        v-loading="loading"
        element-loading-text="合成图片中..."
        element-loading-spinner="el-icon-loading"
        :visible.sync="actionListDrawer">
            <div class="slot" slot="title" v-if="!loading">
                <span class="titleSpan">全面检查</span>
            </div>
            <div class="con-box" ref="imageCheckAll">
            <ActionList :waiting="loading" :data="currentAdvise"/>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import ActionList from '../../components/ActionList.vue'
import {resetJsonBy} from '@/utils/util.js'
import ECHART from '@/components/ECHART.vue'
import Reveal from 'reveal.js';
import ppttitle from '@/components/ppt/ppt-title'
var app={}
const posList = [
  'left',
  'right',
  'top',
  'bottom',
  'inside',
  'insideTop',
  'insideLeft',
  'insideRight',
  'insideBottom',
  'insideTopLeft',
  'insideTopRight',
  'insideBottomLeft',
  'insideBottomRight'
];
app.configParameters = {
  rotate: {
    min: -90,
    max: 90
  },
  align: {
    options: {
      left: 'left',
      center: 'center',
      right: 'right'
    }
  },
  verticalAlign: {
    options: {
      top: 'top',
      middle: 'middle',
      bottom: 'bottom'
    }
  },
  position: {
    options: posList.reduce(function (map, pos) {
      map[pos] = pos;
      return map;
    }, {})
  },
  distance: {
    min: 0,
    max: 100
  }
};
app.config = {
  rotate: 90,
  align: 'left',
  verticalAlign: 'middle',
  position: 'insideBottom',
  distance: 15,
  onChange: function () {
    const labelOption = {
      rotate: app.config.rotate,
      align: app.config.align,
      verticalAlign: app.config.verticalAlign,
      position: app.config.position,
      distance: app.config.distance
    };
    this.$echarts.setOption({
      series: [
        {
          label: labelOption
        },
        {
          label: labelOption
        },
        {
          label: labelOption
        },
        {
          label: labelOption
        }
      ]
    });
  }
};
const labelOption = {
  show: true,
  position: app.config.position,
  distance: app.config.distance,
  align: app.config.align,
  verticalAlign: app.config.verticalAlign,
  rotate: app.config.rotate,
  formatter: '{c}  {name|{a}}',
  fontSize: 16,
  rich: {
    name: {}
  }
};
export default {
    components:{
        ppttitle,
        ECHART,
        ActionList
    },
    filters:{
        getFormart(time){
            //let arr=time.split(" ")[0]
            let array = time.split(" ")[0]
            let m = array.split("-")
            return m[1]+'-'+m[2]
        }
    },
    data(){
        return{
            id:"",//ppt的id
            actionListDrawer:false,
            currentAdvise:[],//当前全面检查内容
            timerange:[],
            loading:false,
            windowWidth:0,
            windowHeight:0,
            bg2:"https://yh-1255992969.cos.ap-guangzhou.myqcloud.com/images/ppt-bg-01.png",
            data:{
                title:"4月虫害防治工作回顾",
                region:"广佛",
                company:"广州粤洪灭鼠杀虫服务有限公司",
                date:"2023-04-20",
                cover_bgUrl:"https://yh-1255992969.cos.ap-guangzhou.myqcloud.com/images/d8de271b2ece0c97a58343fc11d72a0.jpg",
                timeRange:{
                    start:"2023-03-01",
                    end:"2023-04-01"
                }
                ,section:[
                    {
                        title:"虫害情况",
                        tableData:[{
                            total_amount:195,
                            R_account:50,
                            R_mouse_account:10,
                            R_roach_account:5,
                            R_fly_account:15,
                            R_other_account:0
                        }],
                        tableDataByOM:[],
                        chartData:{
                            OM:[],
                            datalist:{
                                chart_mouse_data:[],
                                chart_roach_data:[],
                                chart_fly_data:[],
                                chart_other_data:[]
                            }
                        }
                    }
                ],
                extra_section:{
                    extra_video_info:{
                        url:"https://yh-1255992969.cos.ap-guangzhou.myqcloud.com/video/高基街老鼠从下水道入侵.mp4",
                        list:[{
                            icon:"el-icon-question",
                            title:"老鼠从地漏入侵：高基街/高明时代广场"
                        },{
                            icon:"",
                            title:"改善建议：固定或更换（可以）固定的地漏盖"
                        }]
                    },
                    third_party_inspection:[
                        {
                            title:"第三方：1400029信联餐厅",
                            images:[],
                            third_party_title:"第三方检查结果",//第三方检查不通过原因简写
                            summary:[{
                                time:"",
                                content:"第三方在后区裹粉位发现果蝇>5只，扣FS1，在大堂点餐机位置发现鼠粪，消防栓发现大蠊尸体"
                            },{
                                time:"2023-04-11",
                                content:"餐厅外围施工，存在较大虫害风险",
                                images:['https://admin.yhipm.cn/admin/upload/WechatIMG2.jpeg']
                            }],//第三方检查虫害汇总,
                            pco_party_title:"PCO之前报告显示为虫害高风险",//pco检查内容标题
                            pco_summary:[{
                                time:"2023-04-06",
                                content:"餐厅存在蟑螂和飞虫高风险，后区沙井内清洁不足，有大量飞虫幼虫活动；后区和大堂消防栓内有多只大蠊活体，内部有缝隙未封堵。",
                                images:['https://crystal-1256351955.cos.ap-shanghai.myqcloud.com/06a9d880-a1cd-4bc3-b4d5-4ceecd95618a.jpg','https://crystal-1256351955.cos.ap-shanghai.myqcloud.com/8b637a85-ce4f-49a4-81aa-efe4ed5a2c0c.jpg']
                            },{
                                time:"2023-04-12",
                                content:"餐厅后区沙井内未清洁，仍有大量飞虫幼虫活动；发现消防栓内等缝隙未封堵，现场协助餐厅封堵。点餐机管道与天花板之间有缝隙，导致鼠粪掉落。后续方案：1/对室外下水道放缓释杀虫剂，外墙边际滞留处理；2/室内沙井等孳生地药物处理；3/餐厅需要继续配合封堵缝隙和做好卫生清洁",
                                images:['https://crystal-1256351955.cos.ap-shanghai.myqcloud.com/eb088ec2-13ce-489a-a6fd-c8598f8e4e9a.jpg','https://crystal-1256351955.cos.ap-shanghai.myqcloud.com/1f86c4a6-4193-4f46-95ad-f4851d2ba4d0.jpg','https://crystal-1256351955.cos.ap-shanghai.myqcloud.com/6a053f75-d607-4148-8fd1-2483a00c5f96.jpg']
                            }],//pco之前检查汇总
                            pco_after_summary:[""],//pco之后检查汇总
                        },{
                            title:"第三方：1400503环球港餐厅",
                            images:[],
                            third_party_title:"第三方检查结果",//第三方检查不通过原因简写
                            summary:[{
                                time:"2023-04-11",
                                content:"第三方在后区发现活体大蠊1只，扣分FS1",
                                images:['https://admin.yhipm.cn/admin/upload/WechatIMG3.jpeg']
                            }],//第三方检查虫害汇总,
                            pco_party_title:"PCO检查报告情况",//pco检查内容标题
                            pco_summary:[{
                                time:"2023-03-26",
                                content:"未发现大蠊迹象，餐厅后区多条穿墙管道缝隙，下水管道口未封堵，建议封堵；对外门底部有缝隙，建议加门封条",
                                images:['https://crystal-1256351955.cos.ap-shanghai.myqcloud.com/c408a704-cb14-4a75-a763-45eb925d816c.jpg','https://crystal-1256351955.cos.ap-shanghai.myqcloud.com/00c72b0e-e9b4-4454-bde4-c89c0b3b803c.jpg','https://crystal-1256351955.cos.ap-shanghai.myqcloud.com/9ce440b7-9b3f-40f0-a588-e89c9b65601e.jpg']
                            },{
                                time:"2023-04-11",
                                content:"PCO检查外围发现大蠊尸体1只，对外门底部缝隙过大，有入侵风险，同时后区天花上捕获6只大蠊，后区天花竖井内发现有大蠊，提出封堵方案建议餐厅封堵",
                                images:['https://crystal-1256351955.cos.ap-shanghai.myqcloud.com/4f638fca-bf83-4482-86ef-dcb6e8ece169.jpg','https://crystal-1256351955.cos.ap-shanghai.myqcloud.com/2c2f1829-9fef-4e97-b327-5b82e5358b56.jpg','https://crystal-1256351955.cos.ap-shanghai.myqcloud.com/2dd154fc-2b43-4f55-b75e-b8a8d210750e.jpg']
                            }],//pco之前检查汇总
                            pco_after_summary:[""],//pco之后检查汇总
                        }
                    ]
                }
            },
            style:{
                height:0
            }
        }
    },
    created(){
        
    },
    mounted(){
        this.id = this.$route.params.id
        this.initPPT()
        if(this.id){
            this.getPPTItem()
        }else{
            this.get_Analyze_data()
        }
        this.style.height = (window.innerHeight-50)+'px'
        this.windowWidth = window.innerWidth-50
        //默认时间
        this.timerange = [this.data.timeRange.start,this.data.timeRange.end]
    },
    methods:{
        tableRowClassName({row}) {
            if (!row.status*1) {
                return 'warning-row';
            }
            return '';
        },
        save(){
            let params = {
                method:"SaveMCPPT",
                data:JSON.stringify(this.data),
                title:this.data.title
            }
            this.loading = true
            this.$ajax.post(this.API.api,params).then(()=>{
                        this.loading = false
                        this.$message({
                            message:"保存成功！ ",
                            type: 'success'
                        });
                    }).catch(err=>{
                        console.log(err)
                        this.loading = false
                        this.$message({
                            message: err.msg?err.msg:err,
                            type: 'warning'
                        });
                    })
        },
        //获取ppt
        getPPTItem(){
            let params = {
                method:"getPPTItem",
                id:this.id
            }
            this.loading = true
            this.$ajax.post(this.API.api,params).then((res)=>{
                        this.loading = false
                        if(res.data){
                            let data = JSON.parse(res.data)
                        data.extra_section.extra_video_info =this.data.extra_section.extra_video_info
                        //console.log(data.extra_section)
                        this.data = data
                        }
                    }).catch(err=>{
                        //console.log(err)
                        this.loading = false
                        this.$message({
                            message: err.msg?err.msg:err,
                            type: 'warning'
                        });
                    })
        },
        //查看全面检查
        viewAction(data,index){
            let row = JSON.parse(JSON.stringify(data))
            if(!row[index]){
                return
            }
            row[index].actionList = JSON.parse(row[index].actionList)
            this.currentAdvise = row[index] 
            this.actionListDrawer = true
        },
        initPPT(){
            Reveal.initialize({autoPlayMedia: true,width:window.innerWidth,height:window.innerHeight,slideNumber: true,showNotes: 'separate-page',margin: 0.04,});
            //this.get_Analyze_data()
            console.log(window.innerWidth,window.innerHeight)
            Reveal.layout();
            Reveal.on( 'slidechanged', event => {
            // event.previousSlide, event.currentSlide, event.indexh, event.indexv
            //console.log(event)
                if(event.indexh==1){
                    this.initChart()
                }
            } );
        },
        initChart(){
            //第二张ppt初始化echart
                    let option1={
                        title: {
                            text: '虫害风险占比',
                            subtext: 'R级风险占比',
                            left: 'center'
                        },
                        tooltip: {
                            trigger: 'item'
                        },
                        legend: {
                            orient: 'vertical',
                            left: 'left'
                        },
                        series: [
                            {
                            name: '虫害风险占比',
                            type: 'pie',
                            radius: '50%',
                            label:{
                                row:true,
                                formatter:function(params){
                                    return `${params.data.name}:${params.data.value}`
                                }
                            },
                            data: [
                                { value: this.data.section[0].tableData[0].R_mouse_account, name: '鼠患' },
                                { value: this.data.section[0].tableData[0].R_roach_account, name: '蟑患' },
                                { value: this.data.section[0].tableData[0].R_fly_account, name: '飞虫' },
                                { value: this.data.section[0].tableData[0].R_other_account, name: '其它虫害' }
                            ],
                            emphasis: {
                                itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                            }
                        ]
                        };
                    this.$refs['echart1'].myEcharts(option1)

                    //图表2
                    let option2 = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                        type: 'shadow'
                        }
                    },
                    color: [
                        '#2E8B57',
                        '#FF6347',
                        '#FF9912',
                        '#BC8F8F',
                        '#A066D3',
                        '#749f83',
                        '#ca8622',
                        '#bda29a',
                        '#6e7074',
                        '#546570',
                        '#c4ccd3'
                    ],
                    legend: {
                        data: ['鼠患', '蟑患', '飞虫R级', '其它R级']
                    },
                    toolbox: {
                        show: true,
                        orient: 'vertical',
                        left: 'right',
                        top: 'center',
                        feature: {
                        mark: { show: true },
                        dataView: { show: true, readOnly: false },
                        magicType: { show: true, type: ['line', 'bar', 'stack'] },
                        restore: { show: true },
                        saveAsImage: { show: true }
                        }
                    },
                    xAxis: [
                        {
                        type: 'category',
                        axisTick: { show: false },
                        axisLabel:{rotate:45},
                        data: this.data.section[0].chartData.OM
                        }
                    ],
                    yAxis: [
                        {
                        type: 'value'
                        }
                    ],
                    series: [
                        {
                        name: '总计服务数量',
                        type: 'bar',
                        barGap: 0,
                        label: labelOption,
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.data.section[0].chartData.OM_counts
                        },
                        {
                        name: '鼠患',
                        type: 'bar',
                        barGap: 0,
                        label: labelOption,
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.data.section[0].chartData.chart_mouse_data
                        },
                        {
                        name: '蟑患',
                        type: 'bar',
                        label: labelOption,
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.data.section[0].chartData.chart_roach_data
                        },
                        {
                        name: '飞虫R级',
                        type: 'bar',
                        label: labelOption,
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.data.section[0].chartData.chart_fly_data
                        },
                        {
                        name: '其它R级',
                        type: 'bar',
                        label: labelOption,
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.data.section[0].chartData.chart_other_data
                        }
                    ]
                    };
                    this.$refs['echart2'].myEcharts(option2)
        },
        //按照sectionCount大小分组
        listSplitWidth(list,sectionCount){
            let arrTemp=[]
            let index=0
            !sectionCount ?sectionCount = 5 :''
            for(let i=0;i<list.length;i++){
                index  =parseInt(i/sectionCount)
                if(arrTemp.length<=index){
                    arrTemp.push([])
                }
                arrTemp[index].push(list[i])
            }
            return arrTemp
        },
        getHtmlPlainText(html_str) {
            //提取字符串中的文字
            let re = new RegExp('<[^<>]+>', 'g')
            if(!html_str){
                html_str=""
            }
            let text = html_str.replace(re, '')
            //或
            //var text = html_str.replace(/<[^<>]+>/g, "");
            return text
        },
        //获取工单中的风险项目
        getRiskBy(its){
            let mouseRiskList=[],roachRiskList=[],flyRiskList=[],otherRiskList=[],_this=this
            try {
                let arr = JSON.parse(its.actionList)
                arr.forEach(item=>{
                    item.time = its.start
                    //console.log(item.risk,'_____')
                    switch(_this.getHtmlPlainText(item.risk)){
                        case "鼠类":
                            mouseRiskList.push(item)
                            break;
                        case "飞虫":
                            flyRiskList.push(item)
                            break;
                        case "蟑螂":
                            roachRiskList.push(item)
                            break;
                        case "其它虫害":
                            otherRiskList.push(item)
                            break;
                    }
                })
                return {
                    mouseRiskList,
                    flyRiskList,
                    roachRiskList,
                    otherRiskList
                }
            } catch (error) {
                console.log(error)
                return {
                    mouseRiskList,
                    flyRiskList,
                    roachRiskList,
                    otherRiskList
                }
            }
        },
        //获取分析数据
        get_Analyze_data(){
            this.loading=true
            let params = {
                method:"get_Analyze_data",
                start:this.data.timeRange.start+' 00:00:00',
                end:this.data.timeRange.end+' 23:59:59',
                region:this.data.region
            }
            this.loading=true
            this.$ajax.post(this.API.api,params).then(res=>{
                this.loading=false
                let data = res.data 
                let OM=[],chart_mouse_data=[],chart_fly_data=[],chart_roach_data=[],chart_other_data=[]
                if(data){
                    //去重计算总共服务多少家餐厅
                   let resultArr = data.filter((item, index, selfArr) => {
                    return selfArr.findIndex((x) => x.num === item.num) === index;
                    });
                    this.data.section[0].tableData[0].total_amount = resultArr.length
                    //console.log(resultArr)
                    //根据om分组数据
                    let dataByOM = resetJsonBy(data,'om'), TOTAL_R=0,TOTAL_R_MOUSE=0,TOTAL_R_ROACH=0,TOTAL_R_FLY=0,TOTAL_R_OTHER=0
                    dataByOM.forEach(item=>{
                        let flyRisk=[],mouseRisk=[],roachRisk=[],otherRisk=[],totalRisk=[]
                        item.data.forEach(it=>{
                            if(it.mouseRisk=="R"){
                                mouseRisk.push(it)
                                totalRisk.push(it)
                                //获取当前工单相应风险项目
                            }
                            if(it.roachRisk=="R"){
                                roachRisk.push(it)
                                totalRisk.push(it)
                            }
                            if(it.flyRisk=="R"){
                                flyRisk.push(it)
                                totalRisk.push(it)
                            }
                            if(it.otherRisk=="R"){
                                otherRisk.push(it)
                                totalRisk.push(it)
                            }
                                let {mouseRiskList,roachRiskList,flyRiskList,otherRiskList} = this.getRiskBy(it)
                                it.mouseRiskList = mouseRiskList
                                it.roachRiskList = roachRiskList
                                it.flyRiskList = flyRiskList
                                it.otherRiskList = otherRiskList
                        })
                        //去重后赋予值，totalRisk既
                        item.totalRisk = resetJsonBy(totalRisk,'num')
                        item.flyRisk = resetJsonBy(flyRisk,'num')
                        item.mouseRisk = resetJsonBy(mouseRisk,'num')
                        item.roachRisk = resetJsonBy(roachRisk,'num')
                        item.otherRisk = resetJsonBy(otherRisk,'num')
                        item.data = resetJsonBy(item.data,'num')
                        //let total_num = item.flyRisk.length+item.mouseRisk.length+item.roachRisk.length+item.otherRisk.length
                        TOTAL_R +=item.totalRisk.length
                        console.log(TOTAL_R,totalRisk.length,'-----')
                        TOTAL_R_MOUSE+=item.mouseRisk.length 
                        TOTAL_R_ROACH +=item.roachRisk.length 
                        TOTAL_R_FLY += item.flyRisk.length
                        TOTAL_R_OTHER+=item.otherRisk.length
                    })
                    //console.log(TOTAL_R,'pppppp')
                    //组装数据
                    this.data.section[0].tableData[0].R_account = TOTAL_R 
                    this.data.section[0].tableData[0].R_mouse_account = TOTAL_R_MOUSE 
                    this.data.section[0].tableData[0].R_roach_account = TOTAL_R_ROACH 
                    this.data.section[0].tableData[0].R_fly_account = TOTAL_R_FLY 
                    this.data.section[0].tableData[0].R_other_account = TOTAL_R_OTHER
                    //console.log(dataByOM)
                    //初始化虫害根据OM分类chart_mouse_data=[],chart_fly_data=[],chart_roach_data=[],chart_other_data=[]
                    let OM_counts = []
                    this.data.section[0].tableDataByOM = dataByOM
                    //组装虫害R级分类
                    let TOTAL_MOUSE_LIST=[],TOTAL_ROACH_LIST=[],TOTAL_FLY_LIST=[],TOTAL_OTHER_LIST=[]
                    //console.log(dataByOM)
                    dataByOM.forEach(item=>{
                        OM.push(item.om)
                        OM_counts.push(item.data.length)
                        chart_mouse_data.push(item.mouseRisk.length)
                        chart_fly_data.push(item.flyRisk.length)
                        chart_roach_data.push(item.roachRisk.length)
                        chart_other_data.push(item.otherRisk.length)
                        //组装分类虫害数据
                        TOTAL_MOUSE_LIST = TOTAL_MOUSE_LIST.concat(item.mouseRisk)
                        TOTAL_ROACH_LIST = TOTAL_ROACH_LIST.concat(item.roachRisk)
                        TOTAL_FLY_LIST = TOTAL_FLY_LIST.concat(item.flyRisk)
                        TOTAL_OTHER_LIST = TOTAL_OTHER_LIST.concat(item.otherRisk)
                    })
                    this.data.section[0].chartData.chart_mouse_data = chart_mouse_data
                    this.data.section[0].chartData.chart_roach_data = chart_roach_data 
                    this.data.section[0].chartData.chart_fly_data = chart_fly_data 
                    this.data.section[0].chartData.chart_other_data = chart_other_data
                    this.data.section[0].chartData.OM_counts = OM_counts
                    //初始化数据表
                    //console.log(OM)
                    console.log(TOTAL_OTHER_LIST.length,TOTAL_FLY_LIST.length,TOTAL_MOUSE_LIST.length,TOTAL_ROACH_LIST.length)
                    this.data.section[0].chartData.OM = OM
                    this.data.section[1] = {
                        TOTAL_MOUSE_LIST:this.listSplitWidth(TOTAL_MOUSE_LIST),
                        TOTAL_ROACH_LIST:this.listSplitWidth(TOTAL_ROACH_LIST),
                        TOTAL_FLY_LIST:this.listSplitWidth(TOTAL_FLY_LIST),
                        TOTAL_OTHER_LIST:this.listSplitWidth(TOTAL_OTHER_LIST)
                    }
                    console.log(this.data)
                    //console.log(this.data.section[1].TOTAL_MOUSE_LIST)
                    this.initChart()
                    this.loading = false
                }
                
            }).catch(err=>{
                console.log(err)
                this.loading = false 
                this.$message({
                    message: err.msg||'获取数据失败！',
                   type: 'warning'
                });
            })
        }
        ,//选择时间
        changeDatePicker(e){
            console.log(e)
            this.data.timeRange.start = e[0]
            this.data.timeRange.end = e[1]
            this.get_Analyze_data()
        }
    }
}
</script>
<style lang="scss">
$--webkit-control-background:none !default;
$--r-heading-color:#ffffff;
$--r-background-color:none;
 .el-table .warning-row {
    background: oldlace;
  }
.v-list-item{
    line-height: 55px;
    font-size: 0.8em;
    color: rgb(83, 82, 82);
}
span.it{
    cursor: pointer;
}
span.it:hover{
    color:#5592a8;
}
section>section{
    background:none;
    background-color:none!important;
}
.reveal{
    position: inherit;
}
.PPT-date{
    font-size: 20px;
}
.PPT-subtitle{
    margin-top: 50px;
}
.PPT-subtitle,.PPT-date{
    opacity: 0.8;
    text-align: right;
    font-size: 0.8em;
    padding-right: 10%;
    color:#ffffff!important;
}
H1.PPT-title{
    font-size:90px;
    color:#ffffff;
}
.flex-content{
    display: flex;
    flex-direction: column;
    
}
.title-content{
        flex:1;
    }
    .main-content{
        flex:5;
        .flex{
            display: flex;
        }
    }
    .margin-top{
        margin-top:10px;
    }
.table-row{
    text-align: center;
}
.bottom-selector{
    font-size: 16px;
    position:absolute ;
    color:rgb(241, 238, 238);
    z-index:1;
    bottom:20px;
}
    .el-date-editor .el-range-editor .el-input__inner .el-date-editor--daterange{
        background: none !important;;
    color:#ffffff !important;
    }
.el-input__inner,.el-range-input{
    background-color: rgba(255,255,255,0) !important;
    border:none;
    color:#ffffff !important;
}
.el-range-separator,input{
    color:#ffffff !important;
}
.flex-l-r{
    display: flex;
}
.flex-l-r >.left{
    flex:1;
}
.flex-l-r>.right{
    flex:1.5;
}
.third_party_title{
    color:#33A1C9;
}
// *{
//     color:#333;
// }
.el-timeline-item__content{
    font-size: 22px;
    color:#666;
}
.el-timeline-item__timestamp{
    font-size: 18px;
}
.timeline{
    padding-top: 40px;
}
.timeline{
    display: flex;
}
.t-l-box{
    flex:20;
}
.r-l{
    flex:1;
}
.main-content{
    color:#999;
}
.el-table th.el-table__cell{
    background: rgb(252, 172, 1);
    color:#fff;
}
.video{
    width:50%;
}
</style>