<template>
    <div class="title-ppt">
        <div class="m-ppt-title" :style="{'text-align':textAlign}">
            <i :class="icon"/>
            {{title}}</div>
    </div>
</template>
<script>
export default {
    props:{
        title:{
            type:String,
            default:"标题"
        },
        textAlign:{
            type:String,
            default:"left"
        },
        icon:{
            type:String,
            default:"el-icon-caret-right"
        }
    },
    data(){
        return{

        }
    }
}
</script>
<style lang="less" scoped>
.m-ppt-title{
    text-align: left;
}
</style>